import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

console.log('\nLooking for source code? Contact %c %s%s%s \n\n', 'background: #000; color: #4ebe73', 'yogesh', '@', 'lonkar.org')
console.log(process.env.REACT_APP_VERSION || 'local');

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);
