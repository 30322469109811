import {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.scss';
import ThemeSwitcher from './components/themeswitcher';
import './pages/pages.scss';

const About = lazy(() => import('./pages/about'));
const Akshara = lazy(() => import('./pages/akshara'));
const Alphabets = lazy(() => import('./pages/alphabets'));
const Anka = lazy(() => import('./pages/anka'));
const Home = lazy(() => import('./pages/home'));
const Numbers = lazy(() => import('./pages/numbers'));
const Privacy = lazy(() => import('./pages/privacy'));

const Loading = () => (
  <div>...</div>
);

function App() {
  return (
    <div className="app">
      <ThemeSwitcher hidden/>
      <Suspense fallback={<Loading/>}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/akshara" element={<Akshara/>}>
              <Route path=":akshar" element={<Akshara/>}/>
            </Route>
            <Route path="/anka" element={<Anka/>}>
              <Route path=":anka" element={<Anka/>}/>
            </Route>
            <Route path="/alphabets" element={<Alphabets/>}>
              <Route path=":alphabet" element={<Alphabets/>}/>
            </Route>
            <Route path="/numbers" element={<Numbers/>}>
              <Route path=":number" element={<Numbers/>}/>
            </Route>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/about" element={<About/>}/>
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
