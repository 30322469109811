import {useState} from "react";
import './themeswitcher.scss';

let colorScheme = localStorage.getItem("color-scheme");

if (!colorScheme) {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
    let st = '🎉 Dark mode is supported';
    const queryDark = window.matchMedia('(prefers-color-scheme: dark)');
    if (queryDark.matches) {
      colorScheme = 'dark';
      st += ', setting it as default';
    }
    console.info(st);
  }
}

function ThemeSwitcher({ hidden }) {
  const [current, setCurrent] = useState({
    darkMode: colorScheme === 'dark',
    onAngle: colorScheme === 'dark' ? 0 : 180,
    offAngle: colorScheme === 'dark' ? 180 : 0,
  });
  const toggle = () => {
    const to = {
      darkMode: !current.darkMode,
      onAngle: current.onAngle - 180,
      offAngle: current.offAngle - 180,
    };
    localStorage.setItem("color-scheme", to.darkMode ? "dark" : "light");
    setCurrent(to);
  }
  document.documentElement.setAttribute("data-theme", current.darkMode ? "dark" : "light");
  return (
    <button hidden={hidden} className="theme-switcher" onClick={toggle}>
      <span className="on" style={{transform: `rotate(${current.onAngle}deg)`}}>🌒</span>
      <span className="off" style={{transform: `rotate(${current.offAngle}deg)`}}>🔆</span>
    </button>
  );
}

export default ThemeSwitcher;
